.main-admin-panel {
  width: 100%;
  height: 90vh;
  overflow-y: auto;
}

.main-card-content {
  // width: calc(100% - 115px);
  height: auto;
  display: flex;
  align-items: flex-start;
  height: auto;
  margin-bottom: 30px;
  margin: 0px 7px 30px 7px !important;
  // min-height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
  // flex-direction: column;
}

.card-section {
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-start;
  background-color: #f5f7fa;
  min-width: 285px;
  height: 196px;
  flex-shrink: 0;
  margin-top: 70px;
  flex: 1;
  max-width: calc(25% - 20px);
  margin-bottom: 70px;
}

.card {
  border: none !important;
}

.admin-panel-card {
  border-radius: 15px !important;
}

.admin-panel-card .card-circle {
  margin-top: -60px;
  overflow: hidden;
}

.dashboardIconOuterWhite {
  border: 15px solid #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboardIconOuter {
  border: 15px solid rgb(242, 246, 243);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.card-icon,
.userGuideIcon,
.ObjectDeleteLogIcon {
  position: absolute;
}
.ObjectDeleteLogIcon {
  width: 6.4rem;
  height: 6.4rem;
}
.userGuideIcon {
  width: 7rem;
  height: 7rem;
}

.card-title {
  color: #2e2c2a;
  text-align: center;
  font-family: sans-serif;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.34px;
}

.horizontalBar {
  display: flex;
  justify-content: center;
}

.horizontalBarContent {
  width: 65px;
  height: 4px;
  flex-shrink: 0;
  margin: 0px;
  border-radius: 21px;
  background: #f2f6f3;
}

.card-button-name {
  color: #fff;
  font-family: sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.24px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.admin-panel-button {
  width: 100%;
  // height: 32px;
  display: block;
  border-radius: 3px;
  background-color: #1f5492;
  border: none;
  // font-size: 1.4rem;
  color: #fff;
}

.admin-footer {
  width: 94vw;
  height: 30px;
  top: 1040px;
  left: 80px;
  border-top: 1px solid #1f5492;
  margin-top: 13px;
  margin-right: -15px;
  background-color: white;
}

.footer-text {
  color: rgba(0, 0, 0, 0.85);
  font-family: sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  justify-content: center;
  margin: 10px;
}

.footer-text-hightlight {
  color: rgba(129, 194, 64, 0.85);
  font-family: sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

//FcKeywordSearch scss
.formRowAdminSearch {
  // width: 91vw;
  // width: calc(100% - 115px);
  height: 100px;
  flex-shrink: 0;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  margin: 41px 0px 30px 35px !important;
  width: 96.5%;
}

.input-with-icon {
  position: relative;
  display: flex;
  align-items: center;
}

.cross-icon {
  width: 16px;
  height: 16px;
  position: absolute;
  right: 20px;
  cursor: pointer;
}

#keywordSearch {
  height: 35px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid #c8c7c6;
}

// .fsa-btn.clear-btn {
//     flex-shrink: 0;
//     border-radius: 3px;
//     background: #EB5757 !important;
// }
