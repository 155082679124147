.center {
  margin: auto;
  width: 50%;
  text-align: center;
}

.detailViewTitle {
  margin-left: 1.75em;
  font-weight: 700;
  margin-top: 0;
}

.flexrow {
  display: flex;
  align-items: center;
  /* Align items vertically in the center */
}

.rowDeleteIcon {
  width: 2rem;
  /* Adjust the size as needed */
  min-width: 2rem;
}

.k-dropdown .k-dropdown-wrap {
  width: 100%;
  font-size: 0.875em;
}

// .fsa-grid {
//   display: grid;
//   grid-template-columns: repeat(2, 1fr);
//   gap: 10rem; /* Adjust gap as needed */
// }

.detailViewCon {
  display: flex;
  justify-content: space-around;
  max-height: 77vh;
  min-height: 77vh;

  .k-animation-container {
    width: 100%;
  }
  .objectTypeContainer {
    display: flex;
    flex-direction: column;
    width: calc(100vw - 70vw);
    border-radius: 6px;
    padding-right: 1em;

    .objectTypeBody {
      flex: 1;
      border: solid 1px black;
      border-top: none;
      // height: 84%;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      overflow-y: auto;
      // min-height: 84%;
    }
  }

  .fileViewer {
    padding-right: 1em;
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;

    // max-height: 80%;
    // border:1px solid #a7a7a7;

    a {
      color: #0068ad !important;
      text-decoration: underline !important;
    }
  }

  .detailViewer {
    width: calc(100vw - 60vw);
    // margin: 0 2em;

    .k-tabstrip-items-wrapper > .k-tabstrip-items {
      overflow-x: auto;
      flex-wrap: nowrap !important;
    }

    .k-link {
      // border-right:1px solid black ;
      padding: 0 1em !important;
      font-size: 1.125em;
    }

    .k-link:hover {
      color: #494440 !important;
      background-color: transparent !important;
    }
  }
}

.contentRight {
  display: flex;
  flex-direction: column;
  width: 36%;
}

.cardStyle {
  // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  // border: solid 2px rgb(107, 106, 106);
  // border-radius: 5px;
}

.changePage {
  display: flex;
  margin-top: 1.5em;
}

.document {
  padding-bottom: 1em;
  max-width: 100%;
  min-width: 100%;
  overflow-y: auto;
  overflow-x: auto;
}

.k-tabstrip-top > .k-tabstrip-items .k-item.k-state-active {
  box-shadow: 0 -0.4rem 0 0 #205493 inset;
}

.k-link {
  font-size: 1.7rem;
}

.k-tabstrip-items .k-item {
  color: #494440 !important;
  border: none !important;
}

.k-tabstrip-top > .k-tabstrip-items .k-item:hover {
  box-shadow: 0 -0.4rem 0 0 #75716e inset;
}

.k-content {
  border: none !important;
}

.detailContent {
  margin-top: 2em;
}

.metaItem {
  .metaName {
    margin-right: 8px;
    font-weight: bold;
  }
}

.detailSection {
  background: rgba(235, 235, 235, 0.42);
  margin-bottom: 24px;
  border-radius: 5px;
  width: 100%;
  min-width: 16em;
}

.detailSectionTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  margin-bottom: 12px;
  background-color: #e2e2e2;
  padding: 8px 10px;
  border-radius: 5px;

  button {
    margin-right: 12px;
    padding-top: 0;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 0;
    height: 32px;
    background-color: #fff;
    color: #205493;
    border: 0.75px solid #205493;
  }

  h4 {
    margin: 0 !important;
  }

  .clear-btn {
    font-size: 1em;
  }
}

.detailSectionContent {
  padding: 2px 10px;

  .metaItem {
    margin-bottom: 12px;
  }
}

.float-right {
  float: right;
}

.producer-item {
  overflow-y: auto;
  max-height: 20em;
  max-width: 100%;
}

.vote-detail-col {
  border-radius: 5px;
  border: groove;
  padding: 0;
}

.vote-detail {
  font-size: 14px;
  margin-top: 12px;
  margin-bottom: 12px;
  margin-left: 10px;
  margin-right: 10px;
}

.vote-detail-row {
  width: 100%;
}

.k-listview {
  width: 100%;
}

.metadata {
  max-height: fit-content;
  overflow-y: hidden;
  margin-bottom: 1em;
}

.cardStyle {
  // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  // border: solid 2px rgb(107, 106, 106);
  // border-radius: 5px;
  border: 1px solid;
  border-top-width: 0px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}

.returnedData {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  // border-bottom: solid 1px black;
  border-radius: 5px;
  background-color: rgba(235, 235, 235, 0.9);
  padding: 10px;
  margin: 1em 0;
}

.dropdownCustomStyle {
  margin: 0 !important;
  padding-right: 0.5em;
}

// .returnedData:hover {

// }

.keyfont {
  font-weight: bold;
  margin-right: 1em;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  /* Adjust gap as needed */
  display: grid;
}

.valuefont {
  text-align: right;
}

.image-wrapper {
  margin-bottom: 1em;
  overflow: hidden;
  max-height: 700px;
  max-width: 1025px;
  overflow-y: auto;
  overflow-x: auto;
}

img.hover-zoom {
  min-height: 1025px;
  min-width: 650px;
}

img.hover-zoom:hover {
  transform: scale(1.5);
  min-height: 1025px;
  min-width: 650px;
}

// Dispostion information
.disposition-information-text {
  margin-left: 20px;
  color: red;
  font-size: 12px;
}

.disposition-information-section {
  border: 3px solid red;
  height: auto;
  padding: 5px 0px 5px 10px;

  .disposition-information-content {
    margin: 3px 20px;
  }

  .cutoff-date {
    margin-bottom: 10px;
  }

  .cutoff-date-text {
    color: red;
    margin-right: 10px;
  }

  .readonly-datepicker {
    margin-left: 10px;
  }

  .disposition-datepicker {
    margin-left: 30px;
  }

  // .Disposition-disabled-date{
  //     pointer-events: none;
  //      opacity: 0.6
  // }
}

.sortByDropdownHeaderContainer {
  display: flex;
  // justify-content: space-between;
  width: 100%;
  padding: 10px 15px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background-color: #ebebeb;
}
.sortByDropdownTitle {
  margin-top: 0.4rem;
  font-size: 1.6rem;
}
.objectTypeHeaderContainer {
  display: flex;
  // justify-content: space-between;
  width: 100%;
  padding: 10px 15px;
  // border-top-left-radius: 6px;
  // border-top-right-radius: 6px;
  background-color: #dedede;
}
.previewHeaderContainer {
  display: flex;
  // justify-content: space-between;
  width: 100%;
  padding: 10px 15px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background-color: #ebebeb;
}

.sortByDropdownTitle {
  margin-top: 0.4rem;
  font-size: 1.6rem;
}

.objectTypeHeaderContainer {
  display: flex;
  // justify-content: space-between;
  width: 100%;
  padding: 10px 15px;
  // border-top-left-radius: 6px;
  // border-top-right-radius: 6px;
  background-color: #dedede;
}

.previewHeaderContainer {
  display: flex;
  // justify-content: space-between;
  width: 100%;
  padding: 10px 15px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background-color: #ebebeb;
}

.objectTypeHeading {
  font-size: 1.125em;
  font-weight: bold;
  color: #000;
  margin: 0px !important;
  padding: 5px 0px !important;
}

.downloadLogsBtn {
  color: 'fff';
  border-radius: '5px';
  padding: '2rem 0 2rem';
  height: '100%';
  width: '100%';
  text-align: 'center';
  margin-bottom: '1em';
  background: '#53A363';
  text-decoration: none !important;
}

.tableRowSearchDetails {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 5px 5px 10px 5px;
  .objectTypeName {
    // padding: 2rem;
    margin: 0.5rem 0 0.5rem 1rem;
    color: black;
    font-size: 1.7rem;
    font-weight: 600;
    font-family: 'Poppins';
  }

  .clickedObjectTypeName {
    margin: 0.5rem 0 0.5rem 1rem;
    color: black;
    font-size: 1.7rem;
    font-style: bolder;
    font-weight: 800;
  }
  .tableRowObjectType {
    max-width: 90%;
    width: 70%;
    color: rgba(73, 68, 63, 0.76);
    font-size: 1em;
    border-radius: 10px;
    background: rgba(235, 235, 235, 0.42);
    border: solid #dcdcdc 1px;
    // padding: 1rem 1.8rem 1rem 1rem;
    padding: 0;
    margin-right: 0.8rem;
    cursor: pointer;

    .optionsImage {
      display: none;
    }
  }
  .objectTypeContentSeperator {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .tableRowObjectTypeDiv {
    height: 2px;
    width: 100%;
    margin-top: 4px;
    margin-bottom: 4px;
    background-color: #dcdcdc;
  }

  .clickedObjectTypeOption {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 20px;
    border-radius: 10px;
    background-color: white;
    color: #007db8;
    margin-left: 4px;
    margin-right: 4px;
    text-align: center;
  }
  .objectTypeOptions {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 20px;
    border: 1px solid #3a3a3a;
    border-radius: 10px;
    background-color: transparent;
    color: black;
    margin-left: 4px;
    margin-right: 4px;
    text-align: center;
  }

  .tableRowDate {
    width: 119px;
    color: rgba(73, 68, 63, 0.76);
    font-size: 1em;
    border: solid #dcdcdc 1px;
    border-radius: 10px;
    background: rgba(235, 235, 235, 0.42);
    // padding: 1rem 1.8rem 1rem 1rem;
    cursor: pointer;
    font-size: 1em;
    width: 119px;
  }

  .clickedData {
    color: black;
    padding: 0 0.5rem 0 0;
    background-color: #afccf3;
    border: 3px solid #53cbff;
  }
  .clickedDataSection {
    color: #fff;
    background-color: #afccf3;
  }
  .clickedObjectTypeDiv {
    color: #fff;

    height: 2px;
    width: 100%;
    background-color: #53cbff;
  }
}
.dropDown > .k-dropdown-wrap {
  .k-input {
    overflow: 'hidden';
    text-overflow: 'ellipsis';
  }
}
.k-tabstrip > .k-content.k-state-active {
  padding: 1rem 0.8rem 1rem 1rem;
}

@media (max-width: 1300px) {
  .keyfont {
    font-weight: bold;
    margin-right: 1em;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    display: grid;
    font-size: 0.75em;
  }

  .returnedData {
    font-size: 0.85em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    // border-bottom: solid 1px black;
    border-radius: 5px;
    background-color: rgba(235, 235, 235, 0.9);
    padding: 8px;
    margin: 0.95em 0;
    width: 22.3em;
  }

  .objectTypeHeading {
    font-size: 0.95em;
    font-weight: bold;
    color: #000;
    margin: 0px !important;
    // padding: 1px 0px !important;
    padding-right: 2em;
    white-space: nowrap;
  }

  .detailViewCon .detailViewer .k-link {
    font-size: 0.95em;
  }

  .vote-detail-col {
    border-radius: 5px;
    border: groove;
    padding: 0;
  }

  .vote-detail {
    font-size: 0.75em;
    margin-top: 12px;
    margin-bottom: 12px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .vote-detail-row {
    width: 100%;
  }

  .dropDown > .k-dropdown-wrap {
    // white-space: balance;

    .k-input {
      overflow: 'hidden';
      text-overflow: 'ellipsis';
    }
  }

  .objectTypeContainer {
    width: calc(100% - 70vw);
  }

  .detailViewCon {
    .fileViewer {
      width: 40%;
    }
  }

  .detailViewer {
    width: calc(100% - 70vw) !important;

    .k-link {
      padding: 6px 0 !important;
      text-align: center;
      max-width: 120px !important;
    }

    h3 {
      margin: 0 !important;
      font-size: 1em;
    }
  }

  .k-dropdown .k-dropdown-wrap {
    width: 100%;
    font-size: 0.75em;
  }
}

@media (max-width: 1700px) {
  .keyfont {
    font-weight: bold;
    margin-right: 1em;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    display: grid;
    font-size: 0.75em;
  }

  .vote-detail-col {
    border-radius: 5px;
    border: groove;
    padding: 0;
  }

  .vote-detail {
    font-size: 0.75em;
    margin-top: 12px;
    margin-bottom: 12px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .vote-detail-row {
    width: 100%;
  }

  .dropDown > .k-dropdown-wrap {
    // white-space: balance;

    .k-input {
      overflow: 'hidden';
      text-overflow: 'ellipsis';
    }
  }

  .tableRowSearchDetails {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 5px 5px 10px 5px;

    .tableRowObjectType {
      max-width: 90%;
      width: 70%;
      color: rgba(73, 68, 63, 0.76);
      font-size: 1em;
      border-radius: 10px;
      background: rgba(235, 235, 235, 0.42);
      border: solid #dcdcdc 1px;
      // padding: 1rem 1.8rem 1rem 1rem;
      padding: 0;
      margin-right: 0.8rem;
      cursor: pointer;

      .optionsImage {
        display: none;
      }
    }

    .tableRowDate {
      background: #ebebeb6b;
      border: 1px solid #dcdcdc;
      border-radius: 10px;
      color: #49443fc2;
      cursor: pointer;
      font-size: 1em;
      width: 119px;
    }

    .clickedData {
      color: black;
      padding: 0 0.5rem 0 0;
      background-color: #afccf3;
      border: 3px solid #53cbff;
    }
  }

  .metaItem {
    .metaName {
      margin-right: 8px;
      font-weight: bold;
      font-size: 0.85em;
    }
  }

  .detailSectionTitle {
    h4 {
      margin: 0 !important;
      font-size: 0.85em;
    }

    button {
      margin-right: 12px;
      padding-top: 0;
      padding-left: 1rem;
      padding-right: 1rem;
      padding-bottom: 0;
      height: 32px;
      background-color: #fff;
      color: #205493;
      border: 0.75px solid #205493;
    }

    .clear-btn {
      font-size: 0.85em;
      white-space: normal;
    }
  }

  .objectTypeContainer {
    width: calc(100% - 70vw);
  }

  .detailViewCon {
    .fileViewer {
      width: 47%;
    }
  }

  .detailViewer {
    width: calc(100% - 66vw) !important;

    .k-link {
      padding: 6px 0;
      text-align: center;
      max-width: 120px !important;
    }
  }
  .clickedObjectTypeOption {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 16px;
    border-radius: 10px;
    background-color: white;
    color: #007db8;
    margin-left: 0px;
    margin-right: 6px;
    text-align: center;
  }
  .objectTypeOptions {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 16px;
    border: 1px solid #3a3a3a;
    border-radius: 10px;
    background-color: transparent;
    color: black;
    margin-left: 0x;
    margin-right: 6px;
    text-align: center;
  }
}
.modalHeaderTitleStyle {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-bottom: 0.12em;
}
