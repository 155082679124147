
.userInfoModal {

    .modalHeader {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;

        .modalHeaderTitle {
            margin: 0;
            font-size: 24px;
            font-weight: 550;
            color: #484440;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.44px;
            font-family: sans-serif;
        }

        .modalHeaderIcon {
            cursor: pointer;
        }
    }

    .userInfoModalBody {
        padding: 1.5rem;

        .modalBodySection {
            color: var(--text-01-body, #212529);
            font-family: sans-serif;
            // font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.24px;
            padding: 0px 5px;
        }

        .inputFieldSection {
            // height: 80px;
            display: flex;
            font-size: 15px;
            margin: 1.5rem 0rem;
            // gap: 2rem;

            .inputFieldInternalDiv {
                display: flex;
                flex-direction: column;
                width: 100%;
                .mandatoryField{
                    color: red;
                }

                .disabledInput {
                    background-color: hsl(0, 0%, 95%);
                    color: hsl(0, 0%, 60%);
                }
            }

            label {
                display: flex;
                margin-bottom: 0.2rem !important;
            }

            input {
                display: block;
                width: 100%;
                height: 32px;
                border: 1px solid #ced4da;
                border-radius: 4px;
            }

            select {
                display: block;
                width: 100%;
                height: 32px;
                background-color: #fff;
                font-weight: 400;
                border: 1px solid #ced4da;
                border-radius: 4px;
            }

            .radioBtnContainer {
                display: flex;
                align-items: center;
            }

            .dropdownStyle {
                border-radius: 5px;
                background-color: #fff;
                padding: 0 3px !important;
                border: 1px solid #ced4da;
                height: 32px;
            }

            .radioInputOuterDiv {
                display: flex;
                margin-top: 3rem;
                align-items: center;

                .radioBtnText {
                    // padding-left: 0px;
                    // padding-right: auto;
                }

                input {
                    width: 20px;
                    height: 20px;
                    margin: 0px 8px;
                    border-radius: 10px;
                    display: inline-grid;
                }
            }

            .radioInputOuterDiv input[type='radio'] {
                appearance: none;
                -webkit-appearance: none;
                width: 18px;
                height: 18px;
                margin: 0px 8px;
                border-radius: 10px;
                display: inline-grid;
                border: 3px solid #ced4da;
                background-color: white;
            }

            .radioInputOuterDiv input[type='radio']:checked {
                background-color: #1f5492;
            }
        }

        .searchFieldDiv {
            flex: 1 0 20%;
            height: 80px;
        }

        // Uploade image section
        .uploadImageSection {
            display: flex;
            flex-direction: column;
            width: 100%;

            .imageContents {
                input {
                    min-width: 10rem;
                    border: none;
                    height: auto;
                    padding: 0;
                }

                .iconInput {
                    display: flex;
                }

                .imageStyle {
                    width: 8rem;
                    height: 8rem;
                    border-radius: 50%;
                    background-size: cover;
                    background: white;
                    object-fit: contain;
                    border: 3px solid grey;
                    margin-top: -3rem;
                }
            }
        }

    }

    .createRecordBtnStyle {
        background: white;
        color: #1F5492;
        border: 1px solid #1F5492;
        font-weight: 600 !important;
    }
}

.userInfoFooter {
    padding: 0px !important;

    .footerInternalDiv {
        width: 100%;
        height: 53px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .saveRecordBtn {
        background: #28A745 !important;
        color: white !important;
        border-color: transparent !important;
    }

    .cancelRecordBtn {
        background: white;
        color: #1F5492;
        border: 1px solid #1F5492;
    }
}

