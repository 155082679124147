
.job-header {
    flex: 0 0 100%;
    max-height: 20%;
    min-height: 20%;
    max-width: 100%;
    padding-left:10px;
    padding-top:2px;
    background-color: #F0F0F0;
    border-bottom: solid;
    border-color: #DBDAD9;
    border-width: 2px;
    overflow: hidden;
}

.job-description {
    flex: 0 0 100%;
    padding-left:10px;
    margin-top: 10px;
    max-height: 60%;
    min-height: 1.75em;
    max-width: 100%;
    overflow: hidden;
}