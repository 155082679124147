.tableHeaderWithCheckbox {
  cursor: pointer;
  text-align: center;
  background-color: #25a4db;
  cursor: default;
  padding-bottom: 8px;
  display: flex;
  align-items: center;

  .tableCheckboxInput {
    cursor: pointer;
    margin-left: 1rem;
  }

  .tableCheckboxTitle {
    font-weight: 400;
    color: white;
    font-size: 16px;
    font-family: sans-serif;
    flex: 1;
  }
}

.tableHeaderStyle {
  text-align: center;
  background-color: #25a4db;
  // margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  cursor: pointer;
  padding: 8px 0px;
}
.tableHeaderTitle {
  font-weight: 400;
  color: white;
  font-size: 16px;
  font-family: sans-serif;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
