.center {
  margin: auto;
  width: 50%;
  text-align: center;
}

.detailViewTitle {
  margin-left: 1.75em;
  font-weight: 700;
  margin-top: 0;
}

.detailViewCon {
  display: flex;
  justify-content: space-around;
  max-height: 77vh;
  min-height: 77vh;
  .k-animation-container {
    width: 100%;
  }

  .fileViewer {
    padding-right: 1em;
    width: 62%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 80%;
    // border:1px solid #a7a7a7;

    a {
      color: #0068ad !important;
      text-decoration: underline !important;
    }
  }

  .detailViewer {
    width: calc(100% - 60vw);
    // margin: 0 2em;

    .k-link {
      // border-right:1px solid black ;
    }

    .k-link:hover {
      color: #494440 !important;
      background-color: transparent !important;
    }
  }
}

.contentRight {
  display: flex;
  flex-direction: column;
  width: 36%;
}

.cardStyle {
  // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  // border: solid 2px rgb(107, 106, 106);
  // border-radius: 5px;
}

.changePage {
  display: flex;
  margin-top: 1.5em;
}

.document {
  padding-bottom: 1em;
  max-width: 100%;
  min-width: 100%;
  overflow-y: auto;
  overflow-x: auto;
}

.k-tabstrip-top > .k-tabstrip-items .k-item.k-state-active {
  box-shadow: 0 -0.4rem 0 0 #205493 inset;
}

.k-link {
  font-size: 1.7rem;
}

.k-tabstrip-items .k-item {
  color: #494440 !important;
  border: none !important;
}

.k-tabstrip-top > .k-tabstrip-items .k-item:hover {
  box-shadow: 0 -0.4rem 0 0 #75716e inset;
}

.k-content {
  border: none !important;
}

.detailContent {
  margin-top: 2em;
}

.metaItem {
  .metaName {
    margin-right: 8px;
    font-weight: bold;
  }
}

.detailSection {
  background: rgba(235, 235, 235, 0.42);
  margin-bottom: 24px;
  border-radius: 5px;
}

.detailSectionTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  margin-bottom: 12px;
  background-color: #e2e2e2;
  padding: 8px 10px;
  border-radius: 5px;
  button {
    margin-right: 12px;
    padding-top: 0;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 0;
    height: 32px;
    background-color: #fff;
    color: #205493;
    border: 0.75px solid #205493;
  }
  h4 {
    margin: 0 !important;
  }
}

.detailSectionContent {
  padding: 2px 10px;
  .metaItem {
    margin-bottom: 12px;
  }
}

.float-right {
  float: right;
}

.producer-item {
  overflow-y: auto;
  max-height: 20em;
  max-width: 100%;
}

.voteDetailCol {
  border-radius: 5px;
  border: groove;
  padding: 0;
}

.voteDetail {
  font-size: 14px;
  margin-top: 12px;
  margin-bottom: 12px;
  margin-left: 10px;
  margin-right: 10px;
}

.voteDetailRow {
  width: 100%;
}

.k-listview {
  width: 100%;
}

.metadata {
  max-height: fit-content;
  overflow-y: hidden;
  margin-bottom: 1em;
}

.cardStyle {
  // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  // border: solid 2px rgb(107, 106, 106);
  // border-radius: 5px;
  border: 1px solid;
  border-top-width: 0px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}

.returnedData {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  // border-bottom: solid 1px black;
  border-radius: 5px;
  background-color: rgba(235, 235, 235, 0.9);
  padding: 10px;
  margin: 1em 0;
}
.dropdownCustomStyle {
  margin: 0 !important;
}
// .returnedData:hover {

// }

.keyfont {
  font-weight: bold;
  margin-right: 1em;
}

.valuefont {
  text-align: right;
}

.image-wrapper {
  margin-bottom: 1em;
  overflow: hidden;
  max-height: 700px;
  max-width: 1025px;
  overflow-y: auto;
  overflow-x: auto;
}

img.hover-zoom {
  min-height: 1025px;
  min-width: 650px;
}

img.hover-zoom:hover {
  transform: scale(1.5);
  min-height: 1025px;
  min-width: 650px;
}

// Dispostion information
.disposition-information-text {
  margin-left: 20px;
  color: red;
  font-size: 12px;
}

.disposition-information-section {
  border: 3px solid red;
  height: auto;
  padding: 5px 0px 5px 10px;

  .disposition-information-content {
    margin: 3px 20px;
  }

  .cutoff-date {
    margin-bottom: 10px;
  }

  .cutoff-date-text {
    color: red;
    margin-right: 10px;
  }

  .readonly-datepicker {
    margin-left: 10px;
  }

  .disposition-datepicker {
    margin-left: 30px;
  }

  // .Disposition-disabled-date{
  //     pointer-events: none;
  //      opacity: 0.6
  // }
}
.objectTypeContainer {
  width: calc(100vw - 70vw);
  border-radius: 6px;
  padding-right: 1em;
}
.objectTypeHeaderContainer {
  display: flex;
  // justify-content: space-between;
  width: 100%;
  padding: 10px 15px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background-color: #ebebeb;
}
.objectTypeHeading {
  font-size: 18px;
  font-weight: bold;
  color: #000;
  margin: 0px !important;
  padding: 5px 0px !important;
}
.objectTypeBody {
  border: 1px solid black;
  border-top: none;
  height: 92%;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  overflow-y: auto;
  min-height: 92%;
}
.downloadLogsBtn {
  color: 'fff';
  border-radius: '5px';
  padding: '2rem 0 2rem';
  height: '100%';
  width: '100%';
  text-align: 'center';
  margin-bottom: '1em';
  background: '#53A363';
  text-decoration: none !important;
}

.tableRowSearchDetails {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 5px;

  .tableRowObjectType {
    width: 70%;
    color: rgba(73, 68, 63, 0.76);
    font-size: 1em;
    border-radius: 10px;
    background: rgba(235, 235, 235, 0.42);
    padding: 1rem 1.8rem 1rem 1rem;
    cursor: pointer;

    .optionsImage {
      display: none;
    }
  }

  .tableRowDate {
    width: 119px;
    color: rgba(73, 68, 63, 0.76);
    font-size: 1em;
    border-radius: 10px;
    background: rgba(235, 235, 235, 0.42);
    padding: 1rem 1.8rem 1rem 1rem;
    cursor: pointer;
  }

  .clickedData {
    color: #fff;
    background-color: #25a4db;
  }
}

@media (max-width: 1300px) {
  .objectTypeContainer {
    width: calc(100% - 70vw);
  }
  .detailViewCon {
    .fileViewer {
      width: 45%;
    }
  }
  .detailViewer {
    width: calc(100% - 70vw) !important;
    .k-link {
      padding: 6px 0 !important;
      text-align: center;
      max-width: 120px !important;
    }
  }
}

@media (max-width: 1700px) {
  .objectTypeContainer {
    width: calc(100% - 70vw);
  }
  .detailViewCon {
    .fileViewer {
      width: 62%;
    }
  }
  .detailViewer {
    width: calc(100% - 43vw) !important;
    .k-link {
      padding: 6px 0;
      text-align: center;
      max-width: 120px !important;
    }
  }
}
.modalHeaderTitleStyle {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
